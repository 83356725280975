.centered {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px 20px 30px;
  padding-right: 0!important;
  padding-left: 0!important;
}

.form_title {
  text-align: center;
  color: var(--bs-primary);
  width: 100%;
}

.alert-box {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  width: 300px;
}

.error {
  color: var(--bs-red);
}

.back {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.back-button {
  color: var(--bs-primary);
  cursor: pointer;
}

.logo {
  width: 160px;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link {
  color: var(--bs-link-color);
  text-decoration: underline;
  cursor: pointer;
}

.clear {
  margin: 0!important;
  padding: 0!important;
}

.bottom_logo {
  margin-top: auto;
}

.button_box {
  display: flex;
  justify-content: flex-end;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1em;
    color: var(--bs-primary);
    margin-right: 20px;
  }
}

.top_buffer {
  margin-top: 16px;
}
