.label {
  font-size: 0.8rem;
}

.check-block {
  height: 40px;
}

.required {
  color: red;
}