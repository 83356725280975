.centered {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px 20px 30px;
  padding-right: 0!important;
  padding-left: 0!important;
}

.title {
  text-align: center;
  color: var(--bs-primary);
}

.logo {
  width: 160px;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.clear {
  margin: 0 !important;
  padding: 0 !important;
}

.back {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  margin: 24px 0;
  width: 48%;
  @media (max-width: 600px) {
    width: 100%;
  }
  height: 40px;
  background-color: $google-blue;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .google-icon-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 2px;
    background-color: $white;
    margin: 0 0 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .google-icon {
    width: 18px;
    height: 18px;
  }

  .btn-text {
    margin: 0 0 0 10px;
    color: $white;
    font-size: 14px;
    font-family: var(--font-family-sans-serif);
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }

  &:active {
    background: $button-active-blue;
  }
}

.error {
  color: var(--bs-red);
  p {
    color: var(--bs-red);
  }
}

.back-button {
  color: var(--bs-primary);
  cursor: pointer;
}

.error-checkbox {
  color: var(--bs-red);
  p {
    color: var(--bs-red);
  }
}

.sig {
  position: relative;
  box-sizing: border-box;
  height: 40px;
  width: 48%;
  @media (max-width: 600px) {
    width: 100%;
  }
  background-color: #0B78B7;
  vertical-align: center;
  padding-top: 8px;
  color: $white;
  font-size: 14px;
  font-family: var(--font-family-sans-serif);
  padding-left: 58px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
}

.sig:hover {
  background-color: #0469A0;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .24);
}

.sig:active {
  background-color: #066093;
}

.ico-wrapper {
  position: absolute;
  top: -2px;
  left: -6px;
  width: 48px;
  height: 44px;
  background-color: #0B78B7;
  border: 2px solid #085B8C;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 12px;
}

.ico {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 7px;
}

.auth-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.overflowed {
  overflow-y: auto;
}

.mobile_title {
  font-size: 3em;
  font-weight: 700;
  color: var(--bs-primary);
  text-align: center;
}

.mobile_description {
  font-size: 1em;
  font-weight: 600;
  color: var(--bs-primary);
  text-align: center;
}

.bottom_text {
  text-align: center;
  margin-top: auto;
  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }
}

.top_buffer {
  margin-top: 16px;
}

.suggest {
  color: #282828;
  font-size: 12px;
}