.title {
  text-align: center;
  color: var(--bs-primary);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full {
  width: 100%;
  form {
    width: 80%;
  }
}
