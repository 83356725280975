/* stylesheet.scss */

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

* {
  --bs-link-color: #0394C1;
  --font-family-sans-serif: 'Montserrat', sans-serif;
  --bs-font-sans-serif: 'Montserrat', sans-serif;
  --bs-font-monospace: 'League Spartan', sans-serif;
  --font-family-monospace: 'League Spartan', sans-serif;
  --bs-primary: #367DA3!important;
  --bs-btn-bg: #367DA3!important;
  --bs-btn-border-color: #367DA3!important;
  --bs-btn-hover-bg: #367DA3!important;
  --bs-btn-hover-border-color: #2e6b8b!important;
  --bs-btn-active-bg: #2c6b8d!important;
  --bs-btn-active-border-color: #29688a!important;
  --bs-btn-disabled-bg: #1a3847!important;
  --bs-btn-disabled-border-color: #1a3847!important;
}

* {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 900px) {
  body {
    overflow: hidden;
  }
}

@import "bootstrap";
