.info_box {
  background-color: #DFDFDF;
  height: 100%;
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
}

.info_title {
  width: 100%;
  text-align: start;
  font-weight: 700;
  font-size: 1em;
  cursor: pointer;
  color: var(--bs-primary);
}

.name {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 3em;
  color: var(--bs-primary);
}

.pc_box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  div {
    z-index: 10;
  }
  img {
    width: 50%;
    height: auto;
  }
}

.text {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 2em;
  color: var(--bs-primary);
}

.footer_text {
  font-size: 1em;
  text-align: center;
  width: 100%;
  margin-top: auto;
}